@import '~bootstrap/dist/css/bootstrap.min.css';

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import '~@fortawesome/fontawesome-free/css/all.min.css';


@import url('https://fonts.googleapis.com/css?family=Cabin+Condensed');

@import '~react-image-crop/lib/ReactCrop.scss';

body {
  margin: 0;
  //font-family: 'Lexend', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
 *  STYLE 1
 */

.custom-scroll {
  scrollbar-color: #C4C4C4 white !important;
  scrollbar-width: thin !important;
}

.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  border-radius: 10px;
  background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #C4C4C4;
}